import { InputLabel, TextField, Tooltip } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"

interface Props {
    name: string
    label: string
    required?: boolean;
}

export const FreightProfileNameField: React.FC<Props> = ({name, label, required = false}) => {

    const { control } = useFormContext();
   

return (
    <Controller 
    name={name}
    control={control}
    rules={{ required: required ? `${label} field is required` : false }}
    defaultValue=''
    render={({field: { value, onChange}, fieldState: { error } }) => (
        <>
        <InputLabel id="demo-simple-select-standard-label">Freight Profile Name</InputLabel>
        <Tooltip arrow placement="top" title="Choose a Freight Profile name that includes information about mode(s), time period, and/or analysis round (example: TL202301RD1) to ensure clear and specific file identification">

        <TextField onChange={onChange} value={value} variant="outlined" sx={{ width: '66%' }} error={!!error} helperText={error?.message} required={required}/>
        </Tooltip>
        </>
    )}
/>
)



}