import { BehaviorSubject, map } from "rxjs"
import { ClientListResponseModel } from "../../models/common/clientListResponseModel"
import { TransportationModesListResponseModel } from "../../models/common/transportationModesListResponseModel";

export const currAuthUser$ = new BehaviorSubject<string>("");

export const clientList$ = new BehaviorSubject<ClientListResponseModel[]>([]);

export const transportationModes$ = new BehaviorSubject<TransportationModesListResponseModel[]>([]);

export const clientNames$ = clientList$.pipe(
    map((clients: ClientListResponseModel[] ) => clients.map((client) => client.client_name))
)

export const transportationModeNames$ = transportationModes$.pipe(
    map((transportationModes: TransportationModesListResponseModel[]) => transportationModes.map((tMode) => tMode.trans_mode_name))
)


// fetch(`http://localhost:8000/getClients`)
//     .then(res => res.json())
//     .then(data => clientList$.next(data))

// fetch(`http://localhost:8000/getTransportationModes`)
//     .then(res => res.json())
//     .then(data => transportationModes$.next(data))