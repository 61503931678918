import { clientList$ } from "./commonStore";
import { transportationModes$ } from "./commonStore";


export class CommonService {
    constructor(){}
    private static BASEURL = 'http://fp-dev-ec2-lb-common-svc-2040288258.us-east-1.elb.amazonaws.com'


    static async clientNamesGet(){  
        fetch(this.BASEURL+'/getClients')
        .then(res => res.json())
        .then(data => clientList$.next(data))
        .catch(err => console.error("error: ", err))
    }

    
    static async transportationModesGet(){  
        
        fetch(this.BASEURL+'/getTransportationModes')
        .then(res => res.json())
        .then(data => transportationModes$.next(data))
        .catch(err => console.error("error: ", err))
    }


}