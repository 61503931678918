import { Button, Checkbox,  InputLabel, ListItemText, MenuItem, Select } from "@mui/material"
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form"


export interface Props {
    name: string
    label: string
    options: string[]
    checkedValues: string[]
    setCheckedValues: React.Dispatch<React.SetStateAction<string[]>> 
    helperFunction?: (option: string, isChecked: boolean) => void
    required?: boolean;

}

export const MultiCheckStringDropdownField: React.FC<Props> = ({name, label, options, checkedValues, setCheckedValues, helperFunction}) => {

    const { control, setValue } = useFormContext();
    //const [checkedValues, setCheckedValues] = useState<string[]>([]);


    const handleSelect = (value: string) => {
        const isPresent = checkedValues.indexOf(value);
        if(isPresent !== -1) {
            const remaining = checkedValues.filter((item) => item !== value)
            setCheckedValues(remaining);
            
        } else {
            setCheckedValues([...checkedValues,value])
           
        }
    }

    useEffect(() => {
        setValue(name, checkedValues)
    },[name, checkedValues])
   

return (
    <Controller
    name={name}
    control={control}
    defaultValue={options}
    render={() => (
         <>
             
             <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
            
             <Select multiple value={checkedValues} label={label} renderValue={(selected) => selected.map((x) => x).join(', ') } sx={{ width: '66%' }}>
                 { 
                     options.map(option => 
                       
                         <Controller 
                             name={name}
                             control={control}
                             key={option}
                             render={() => (
                                 <MenuItem >
                                 <Checkbox
                                 checked={checkedValues.includes(option)}
                                 onChange={(e) => {
                                     handleSelect(option)
                                     if(helperFunction !== undefined) helperFunction(option,e.target.checked)
                                 }}
                                
                                 />
                                 <ListItemText primary={option} />
                                 </MenuItem>
                             )}
                         />
                     )}
                      <Button onClick={() => {setCheckedValues([])}}> Clear Selected</Button>
             </Select>
             
            
            </>
        
)} />

)


// <Controller
// name={name}
// control={control}
// defaultValue={options}
// render={() => (
//     <>
    
//  <Select multiple value={checkedValues} label={label} renderValue={(selected) => selected.map((x) => x).join(', ')}>
    
// {
//             options.map(option => (
//                         <MenuItem >
//                         <Checkbox
//                         checked={checkedValues.includes(option)}
//                         onChange={(e) => {
//                             handleSelect(option)
//                             if(helperFunction !== undefined) helperFunction(option,e.target.checked)
//                         }}
//                         />
//                         <ListItemText primary={option} />
//                         </MenuItem>
//             ))}   
// </Select>
// </>
// )} />



// <>    
//     <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
//     <Select multiple value={checkedValues} label={label} >
//         {
        
//             options.map(option => 
//                 <Controller 
//                     name={name}
//                     control={control}
//                     key={option}
//                     render={() => (
//                         <MenuItem >
//                         <Checkbox
//                         checked={checkedValues.includes(option)}
//                         onChange={(e) => {
//                             handleSelect(option)
//                             if(helperFunction !== undefined) helperFunction(option,e.target.checked)
//                         }}
                        
//                         />
//                         <ListItemText primary={option} />
//                         </MenuItem>
//                     )}
//                 />
//             )}
//     </Select>
   
// </>

}