import { InputLabel, MenuItem, Select} from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"


export interface Props {
    name: string
    label: string
    options: string[]
    required?: boolean;
}

export const SelectDropdownField: React.FC<Props> = ({name, label, options}) => {

    const { control } = useFormContext();
  
    return (
        <>
            <Controller 
                name={name}
                control={control}
                defaultValue=''
                render={({field: {onChange, value} }) => (
                    <>
                    <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
                    <Select onChange={onChange} value={value} sx={{ width: '66%' }}>
                        {
                            options.map(option => {
                                return (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                )
                            })
                        }

                    </Select>
                    </>
                )}
            />
        </>
    )



}