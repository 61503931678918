import axios from "axios";
import { FreightProfilePostRequest } from "../../models/freightProfile/freightProfilePostRequest";
import { freightProfileHeaders$ } from "./freightProfileStore";




export class FreightProfileService {
    constructor(){
    }
    
    private static BASEURL = 'http://fp-dev-ec2-alb-fpss-svc-697731054.us-east-1.elb.amazonaws.com/api/v1'
    //private static BASEURL = 'http://127.0.0.1:8000/api/v1'

    static async freightProfilePost(req: FreightProfilePostRequest){
        const { data } = await axios.post(`${this.BASEURL}/freight-profiles`, req)
        return data
    }
    
//fp-dev-ec2-alb-fpss-svc-697731054.us-east-1.elb.amazonaws.com

    static async freightProfileHeaderGetById(id: number){
        fetch(`${this.BASEURL}/get-freight-profile-header-by-id/?q=`+ id)
        .then(res => res.json())
        .then(data => {
           
            let arr = freightProfileHeaders$.getValue().filter(header => header.freight_profile_header_id != id.toString())
            arr.unshift(...data)
            freightProfileHeaders$.next(arr)
        })
        .catch(err => console.error("error: ", err))
       }
   


    static async freightProfileHeadersGetAll(){  
       
        fetch(`${this.BASEURL}/get-freight-profile-headers`)
            .then(res => res.json())
            .then(data => freightProfileHeaders$.next(data))
            .catch(err => console.error("error: ", err))
    }

}