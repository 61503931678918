import React, { useEffect, useState } from 'react';
import './App.css';
import LoginPage from './pages/loginPage';
import { Route, Routes } from 'react-router-dom';
import { NewRequestPage } from './pages/newRequestPage';
import { SummaryPage } from './pages/summaryPage';
import { Amplify } from 'aws-amplify';
import AWSCognitoConfig from './constants/AWSCognitoConfig';
import { Grid, Typography } from '@mui/material';
import { Header } from './components/Header';
import { currAuthUser$ } from './state/common/commonStore';
import { FreightProfileService } from './state/freightProfile/freightProfileService';
import useWebSocketHook from './services/hooks/useWebsocketHook';

Amplify.configure(AWSCognitoConfig);

function App() {
  
  //const [user, setUser] = useState(null);
  //const [currentAuthenticatedUser, setCurrentAuthenticatedUser] = useState("");
  const [latestMessage, setLatestMessage] = useState("");

/*
  useEffect(() => {
    currAuthUser$.next(currentAuthenticatedUser);
  },[currentAuthenticatedUser])
  */

  useEffect(() => {
    currAuthUser$.next("ajones@freightplus.io");
  },[])

  //websocket url, connects to the notification channel
  const socketUrl =  'ws://fp-dev-ec2-alb-fpss-svc-697731054.us-east-1.elb.amazonaws.com/notification';


//setCurrentAuthenticatedUser("ajones@freightplus.io");

/*
  // login - comment below to disable login
  if(!user) {
    return <LoginPage currentAuthenticatedUser={currentAuthenticatedUser} setCurrentAuthenticatedUser = {setCurrentAuthenticatedUser} setUser={setUser}/>
  }

  */

  const { lastMessage } = useWebSocketHook(socketUrl, setLatestMessage);

  // handle message
  useEffect(() => {
    //handle response
    if(lastMessage){
    let messageAsJson = JSON.parse(lastMessage?.data);
    if(messageAsJson?.eventType === "urlAdded") {
      const x = async () => await FreightProfileService.freightProfileHeaderGetById(messageAsJson.data)
      x()
      setLatestMessage("urlAdded");
    } else if(messageAsJson?.eventType === "freightProfileHeaderAdded") {
      const x = async () => await FreightProfileService.freightProfileHeaderGetById(messageAsJson.data)
      x()
      setLatestMessage("freightProfileHeaderAdded");
    }
    else if(messageAsJson?.eventType === "ERROR"){
        window.alert("Error uploading freight profile records for header with Id: "+messageAsJson.data)
        const x = async () => await FreightProfileService.freightProfileHeaderGetById(messageAsJson.data)
        x()
        setLatestMessage("Error");
    }
  }
  }, [lastMessage]);


  return (
    <Grid container direction="column" justifyContent="space-between" style={{ minHeight: "100vh" }}>
      <Grid item>
    <Grid rowSpacing={6} container justifyContent={"center"} >
        <Grid item xs={12} sx={{ padding: '0'}} > 
          <Header /> 
        </Grid> 
        <Grid item xs={12} sx={{backgroundColor: "#F5F5F5", position: "relative", zIndex: 1}}>
          <Routes>
            <Route path='/' element={<SummaryPage />} />
            <Route path='/newRequest' element={<NewRequestPage />} />
            <Route path='/summary' element={<SummaryPage latestMessage={latestMessage} />} />
          </Routes>
        </Grid>
    </Grid>
    </Grid>
      <Grid item>
        <footer style={{ height: "10%", backgroundColor: "#00263A"}}>
          <Typography variant="body1" sx={{ color: '#FFFFFF', padding: 2}}>© 2021 FreightPlus</Typography>
        </footer>
      </Grid>
    </Grid>
    
  );
}

export default App;
