import { useEffect, useState } from "react"
import { FreightProfileRequestForm } from "../components/forms/freightProfileRequestForm";
import { Grid, Typography } from "@mui/material";
import { clientNames$, transportationModeNames$ } from "../state/common/commonStore";
import { CommonService } from "../state/common/commonService";
import ClipLoader from "react-spinners/ClipLoader";



export const NewRequestPage = () => {

    const [clients,setClients] = useState<string[]>([]);
    const [transportationModes,setTransportationModes] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        CommonService.clientNamesGet()
        clientNames$.subscribe(setClients)
        setIsLoading(false);

    },[])

    useEffect(() => {
        setIsLoading(true);
        CommonService.transportationModesGet()
        transportationModeNames$.subscribe(setTransportationModes)
        setIsLoading(false);

    },[])

return (
   
    <Grid container spacing={4} padding={5} justifyContent="center" sx={{ marginTop: '2%' }}>
     <Grid item xs={9} > 
        
            <Typography variant="h5">Freight Profile</Typography>
           
        </Grid>
      <Grid item xs={9}  > 
      {isLoading ? (
            <ClipLoader color="blue" />
        ) : (
           <FreightProfileRequestForm clients={clients} tModes={transportationModes} /> 
           )}
           </Grid>
        
    </Grid> 

    )

}