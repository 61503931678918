import { InputLabel} from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Controller, useFormContext } from "react-hook-form"


export interface Props {
    name: string
    label: string
    required?: boolean;
   
}

export const DatePickerField: React.FC<Props> = ({name, label, required= false}) => {

    const { control } = useFormContext();
  
    return (
        <>
            <Controller 
                name={name}
                control={control}
                defaultValue={null}
               
                render={({field: {onChange, value}, fieldState: { error } }) => (
                    <>
                    <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker onChange={onChange} value={value} sx={{ width: '66%' }} />
                   </LocalizationProvider>
                   </>
                )}
            />
        </>
    )



}