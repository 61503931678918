import { Button, Grid } from "@mui/material"
import { DataGrid, GridColDef} from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { freightProfileHeaders$ } from "../state/freightProfile/freightProfileStore";
import { FreightProfileHeaderModel } from "../models/freightProfile/freightProfileHeaderModel";
import { FreightProfileService } from "../state/freightProfile/freightProfileService";
import ClipLoader from "react-spinners/ClipLoader";




const columns: GridColDef[] = [
    {field: 'freight_profile_header_id', headerName: 'Id', width: 50},
    {field: 'freight_profile_name', headerName: 'Name', width: 100},
    {field: 'requester', headerName: 'Requester', width: 150},
    {field: 'actual_ship_start_date', headerName: 'Ship Start Date', width: 150, valueFormatter: (params) => {
        const date = new Date(params.value as string);
        return date.toLocaleDateString('en-US');
    }},
    {field: 'actual_ship_end_date', headerName: 'Ship End Date', width: 150, valueFormatter: (params) => {
        const date = new Date(params.value as string);
        return date.toLocaleDateString('en-US');
    }},
    {field: 'requested_datetime', headerName: 'Requested Date Time', width: 150, valueFormatter: (params) => {
        const date = new Date(params.value as string);
        return date.toLocaleDateString('en-US');
    }},
    {field: 'freight_profile_file_url', headerName: 'URL', width: 200, renderCell: (params) => {
        let currRow = params.row;        
        return currRow.freight_profile_file_url === "" && currRow.freight_profile_header_status === "PENDING"  ? <ClipLoader color="blue"/> : <Button size="small" onClick={() => window.open(`${currRow.freight_profile_file_url}`)}>{currRow.freight_profile_file_url}</Button>}
    },
    {field: 'freight_profile_header_status', headerName: 'Status', width: 100}
];

interface Props {
    latestMessage?: string
}

export const SummaryPage: React.FC<Props> = ({latestMessage}) => {

    const [headers, setHeaders] = useState<FreightProfileHeaderModel[]>([])
    const [gRows, setGRows] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        FreightProfileService.freightProfileHeadersGetAll();
        freightProfileHeaders$.subscribe(setHeaders);
        setIsLoading(false);
    },[]);
    
useEffect(() => {

const gridRows = headers.map(x => {
    return {
        id: x.freight_profile_header_id,
        freight_profile_header_id: x.freight_profile_header_id,
        freight_profile_name: x.freight_profile_name,
        requester: x.requester,
        actual_ship_start_date: x.actual_ship_start_date,
        actual_ship_end_date: x.actual_ship_end_date,
        requested_datetime: x.request_datetime,
        freight_profile_file_url: x.freight_profile_file_url,
        freight_profile_header_status: x.freight_profile_header_status
    }
})
setGRows(gridRows)
},[headers,latestMessage])


    return (
    <Grid container spacing={4} padding={3} justifyContent="center" >  
        <Grid item xs={10}  sx={{ marginTop: '4%' }}> 
        {isLoading ? (
                <ClipLoader color="blue" />
            ) : (
                <DataGrid columns={columns} rows={gRows} getRowId={row => row.freight_profile_header_id}  />
            )}
        </Grid>
    </Grid> 
    )
}