import { Button, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material"
import {  Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { FreightProfileNameField } from "./formFields/freightProfileNameField"
import { MultiCheckStringDropdownField } from "./formFields/multiCheckStringDropdownField"
import { SelectDropdownField } from "./formFields/selectDropdownField"
import { DatePickerField } from "./formFields/datePickerField"
import { FreightProfileService } from "../../state/freightProfile/freightProfileService"
import { Client, TransportationMode } from "../../models/freightProfile/freightProfilePostRequest"
import { currAuthUser$ } from "../../state/common/commonStore"
import { useState } from "react"
import { useNavigate } from "react-router-dom"


export interface IFormFields {
    freightProfileName: string
    selectedClientNames: string[],
    transportationModes: string[],
    baseRate: string,
    shipStartDate: Date,
    shipEndDate: Date,
    freightProfileType: string,
}

export interface Props {
    clients: string[]
    tModes: string[]
}


export const FreightProfileRequestForm: React.FC<Props> = ({clients, tModes}) => {

  const navigate = useNavigate();
  const methods = useForm<IFormFields>({
    });
  
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);
    const [ ltlSelected, setLtlSelected ] = useState(false)
    const [clientCheckedValues, setClientCheckedValues] = useState<string[]>([]);
    const [modeCheckedValues, setModeCheckedValues] = useState<string[]>([]);


    
    const helperFunction = (option: string, isChecked: boolean) => {
        if(option === "LTL" && isChecked === true) {
            setLtlSelected(true)
        } else if(option === "LTL" && isChecked === false) { 
            setLtlSelected(false) 
            methods.resetField("baseRate")
        }
    }
    

    const handleReset = () => {
        setClientCheckedValues([]);
        setModeCheckedValues([]);
        setLtlSelected(false)
        methods.reset();
      };

      const validateForm = (data: IFormFields) => {
        let errorMessage = "";

        if(data.freightProfileName === '') {
            errorMessage = "Freight Profile Name is required"
        } else if(data.selectedClientNames.length === 0) {
            errorMessage = "Client Names are required"
        }
        else if(data.transportationModes.length === 0) {
            errorMessage = "Transportation Modes are required"
        }
        else if(data.shipStartDate === null) {
            errorMessage = "Shipping Start Date is required"
        }
        else if(data.shipEndDate === null) {
            errorMessage = "Shipping End Date is required"
        }
        else if(data.shipStartDate > data.shipEndDate) {
            errorMessage = "Shipping Start Date must be before Shipping End Date"
        }
        else {
          return true;
        }
        window.alert(errorMessage);
        return false;
      }


    const onSubmit: SubmitHandler<IFormFields> = (data: IFormFields) => {

      setSubmitButtonDisabled(true);
        let cList: Client[] = []
        let tList: TransportationMode[] = []
        data.selectedClientNames.forEach(x => cList.push({name: x}))
        data.transportationModes.forEach(x => tList.push({name: x}))
        if(validateForm(data)) {
          console.log(data)
            FreightProfileService.freightProfilePost({
              freightProfileName: data.freightProfileName, 
              requester: currAuthUser$.getValue(),
              clients: cList,
              modes: tList,
              baseRateType: data.baseRate,
              actualShipStartDate: data.shipStartDate,
              actualShipEndDate: data.shipEndDate,
              requestDatetime: new Date(),
              freightProfileType: data.freightProfileType
            }).then(() => navigate("/summary"));
            
      }
      setSubmitButtonDisabled(false);
    }//end onsubmit

    return (
      <Grid container spacing={2} sx={{boxShadow: 3 , backgroundColor: "#FFFFFF", padding: 4 }}>
  <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)} onReset={handleReset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FreightProfileNameField name={"freightProfileName"} label={'Freight Profile Name'} required />
            </Grid>
            <Grid item xs={12}>
              <MultiCheckStringDropdownField name='selectedClientNames' label='Client Names' options={clients}  checkedValues={clientCheckedValues} setCheckedValues={setClientCheckedValues} required/>
            </Grid>
            <Grid item xs={12}>
              <MultiCheckStringDropdownField name="transportationModes" label="Transportation Modes:" options={tModes} checkedValues={modeCheckedValues} setCheckedValues={setModeCheckedValues} helperFunction={helperFunction} required/>
            </Grid>
            <Grid item xs={12}>
              {ltlSelected && <SelectDropdownField name="baseRate" label="Base Rate:" options={['Czarlite 92', 'Czarlite 97']}  />}
            </Grid>
            <Grid item xs={12}>
              <DatePickerField name="shipStartDate" label="Shipping Start Date:" required />
            </Grid>
            <Grid item xs={12}>
              <DatePickerField name="shipEndDate" label="Shipping End Date:" required  />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100%' }}>
            <Grid item xs={12}>
              <Controller
              name={"freightProfileType"}
              control={methods.control}
              defaultValue='standard'
              render={({field: {onChange, value} }) => (
              <RadioGroup
                aria-label="freightProfileType"
                name="freightProfileType"
                value={value}
                onChange={(event) => onChange(event.target.value)}
              >
                <FormControlLabel value="standard" control={<Radio defaultChecked/>} label="Standard Freight Profile" />
                <FormControlLabel value="llma" control={<Radio />} label="Lane Level Market Analysis" />
              </RadioGroup>
              )} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} sx={{ alignSelf: 'flex-end', paddingTop: 41}}>
                <Grid item>
                  <Button variant="contained" color="secondary" type="reset" sx={{ backgroundColor: '#A7A8A9' }} disabled={!methods.formState.isDirty && clientCheckedValues.length === 0 && modeCheckedValues.length === 0}>Discard</Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit" sx={{ ml: 2, backgroundColor: '#00263A' }} disabled={submitButtonDisabled}>Submit</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  </FormProvider>
</Grid>
    )
}


