import useWebSocket from 'react-use-websocket';
import { FreightProfileService } from '../../state/freightProfile/freightProfileService';

const useWebSocketHook = (socketUrl: string, setLatestMessage:  React.Dispatch<React.SetStateAction<string>>) => {
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.log("connection opened");
    },
    share: true,
    onMessage: (message) => {
      //handle response
      let messageAsJson = JSON.parse(message.data);
      if(messageAsJson.eventType === "urlAdded") {
        const x = async () => await FreightProfileService.freightProfileHeaderGetById(messageAsJson.data)
        x()
        setLatestMessage("urlAdded");
      } else if(messageAsJson.eventType === "freightProfileHeaderAdded") {
        const x = async () => await FreightProfileService.freightProfileHeaderGetById(messageAsJson.data)
        x()
        setLatestMessage("freightProfileHeaderAdded");
      }
      else if(messageAsJson.eventType === "ERROR"){
          window.alert("Error uploading freight profile records for header with Id: "+messageAsJson.data)
          const x = async () => await FreightProfileService.freightProfileHeaderGetById(messageAsJson.data)
          x()
          setLatestMessage("Error");
      }
    },
    onClose: () => {
      //handle connection termination
      console.log("connection closed");
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  return { sendMessage, lastMessage, readyState };
};

export default useWebSocketHook;