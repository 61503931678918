//dev config

const AWSCognitoConfig = {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_cuM1L8LiB',
      userPoolWebClientId: '105fnm4hk20tn6jukkkurt5v8j',
  
      oauth: {
        domain: 'nucleus-dev.auth.us-east-1.amazoncognito.com',
        scope: ["email", "openid"],
        redirectSignIn: 'http://fp-dev-ec2-alb-fpss-app-929740235.us-east-1.elb.amazonaws.com/',
        redirectSignOut: 'http://fp-dev-ec2-alb-fpss-app-929740235.us-east-1.elb.amazonaws.com/signout/',
        responseType: 'token'
      },
    },
  }
  
  export default AWSCognitoConfig;