import { AppBar, Button, Grid } from "@mui/material"
import FreightPlusFullLogo from '../resources/FreightPlusFullLogo.jpg'
import { Auth } from "aws-amplify"
import { useNavigate } from "react-router-dom";


export const Header = () => {

    const navigate = useNavigate();

return(
    // <header>
    <AppBar sx={{ height: '10%', backgroundColor: "white" }}>
       <Grid container direction="row" sx={{backgroundColor: "white"}} columnSpacing={2} paddingTop={1}>
            <Grid item xs={8.7}>
                <img src={FreightPlusFullLogo} />
            </Grid>
            <Grid item>
                <Button  color="primary" onClick={() => navigate("/newRequest")} sx={{ color: '#00263A', ':hover': { backgroundColor: '#44D62C' } }}>New</Button>
            </Grid>
            <Grid item>
                <Button  color="primary" onClick={() => navigate("/summary")} sx={{ color: '#00263A', ':hover': { backgroundColor: '#44D62C' } }}>Summary</Button>
            </Grid>
            <Grid item>
                <Button  color="primary" onClick={()=>Auth.signOut()} sx={{ color: '#00263A', ':hover': { backgroundColor: '#44D62C' } }}>Sign Out</Button>
            </Grid>

       </Grid>  
    </AppBar>
    // </header>
)

}
